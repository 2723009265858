import {makeStyles} from "@mui/styles";
import {useLocation} from "react-router-dom";
import Typography from '@mui/material/Typography';
import backgroundLogo from '../../img/Thugz-Mint-Background.png'
import React from "react";
import logo from "../../img/ThugzNFT-Logo.png";

const useStyles = makeStyles({
    MainContainer: {
        backgroundImage: `url(${backgroundLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPositionX: 'center',
        height: '100vh'
    },
    DivAttributes: {
        display: 'flex',
        justifyContent: 'space-between',
        border: 'solid 4px #fad965',
        borderRadius: '15px',
        marginLeft: '35px',
        marginRight: '35px',
    },
    Attributes: {
        border: 'solid',
        borderRadius: '5px',
        borderImage: 'linear-gradient(#ffb750,#f7881b) 0.5',
        width: '200px',
        padding: '5px',
        textAlign: 'center',
        backgroundColor: '#312f2f',

    },
    AttributesContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '16px',
        margin: 'auto',
    },

    ImageContainer: {
        marginLeft: '1rem',
        display: 'flex',
        flexDirection: 'column',
    },
    Image: {
        width: '450px',
        height: '450px',
        borderRadius: '5px',
        margin: '1rem',
    },
    Description: {
        display: 'flex',
        flexDirection: 'column',
        margin: '1rem',
    },
    Name: {}
});

function ThugzDetails() {
    const classes = useStyles();
    const location = useLocation();
    const {item} = location.state;

    return (
        <div className={classes.MainContainer}>
            <div>
                <img src={logo} alt="ThugzNFT Logo White"/>
            </div>
            <div className="logo-main-title">
                <h1 className="gradient-text">THUGZ LIFE RANKING</h1>
            </div>
            <div className={classes.DivAttributes}>
                <div className={classes.ImageContainer}>
                    <img className={classes.Image} src={item.image} alt={item.name}/>
                </div>
                <div className={classes.Description}>
                    <div>
                        <Typography className={`${classes.Name} gradient-text`} gutterBottom variant="h4"
                                    component="div"
                                    color="white">
                            {`${item.name} `}
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div" color="white">
                            {`Rank  #${item.rank} `}

                        </Typography>
                    </div>
                    <div className={classes.AttributesContainer}>
                        {
                            item.attributes.map((attr, index) => (
                                    <div className={classes.Attributes} key={index}>
                                        <Typography variant="body2" className="gradient-text">
                                            {attr.trait_type}
                                        </Typography>
                                        <Typography variant="body2" color="white">
                                            {attr.value}
                                        </Typography>
                                        <Typography variant="body2" color="white">
                                            {((attr.nbOccurence / attr.total) * 100).toFixed(2)} %
                                        </Typography>
                                    </div>

                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThugzDetails;
