import './App.css';
import ThugzList from "./components/ThugzList";
import React, {useState} from "react";
import nfts from './thugz-metadata-ranks.json'
import nftsAttributes from './thugz-metadata-attributes.json'
import SearchFilters from "./components/SearchFilters";
import logo from "./img/ThugzNFT-Logo.png";

function App() {
    let defaultItems = nfts
        .sort((a, b) => b.score - a.score)
    // .slice(1,30)

    const [filteredItems, setFilteredItems] = useState(defaultItems);

    return (
        <div className="App">
            <div>
                <img src={logo} alt="ThugzNFT Logo White"/>
            </div>
            <div className="logo-main-title">
                <h1 className="gradient-text">THUGZ LIFE RANKING</h1>
            </div>
            <div>
                <h2 className="subtitle">911 Thugz escaped to the street. The question is... Do you have the level                   ?</h2>
            </div>
            <div className="main-container">
                <SearchFilters
                    defaultItems={defaultItems}
                    items={filteredItems}
                    setItems={setFilteredItems}
                    attributes={nftsAttributes}
                />
                <ThugzList items={filteredItems}/>
            </div>
        </div>
    );
}

export default App;
