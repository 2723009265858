import AutocompleteCustom from "./AutocompleteCustom/AutocompleteCustom";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {Button} from "@mui/material";

const useStyles = makeStyles({
    SearchFields: {
        marginLeft: '2rem',
        marginRight: '2rem',
        minWidth: '300px',
    },
    MainContainer: {
        marginLeft: '1rem',
    },
    FiltersTitle: {
        fontFamily: "Din pro Condensed Bold Italic",
        fontSize: 'medium'
    },
    AttributesFilter: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderImage: 'linear-gradient(#ffb750,#f7881b) 0.5',
        borderRadius: '0.5rem',
        padding: '0.5rem',
    },
    Button: {
        fontFamily: 'Din pro Condensed bold Italic !important',
        width: '100%',
        "&.MuiButton-root": {
            borderImage: 'linear-gradient(#ffb750,#f7881b) 0.5',
        },
        "&.MuiButton-root:hover": {
            borderColor: "white",
        },

        "&.MuiButton-contained": {
            color: "yellow"
        },
    }
});

function SearchFilters({defaultItems, items, setItems, attributes}) {
    const classes = useStyles();
    const GradientRestartAltIcon = () => (
        <>
            <svg width={0} height={0}>
                <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor="rgba(241,184,74,1)" />
                    <stop offset={1} stopColor="rgba(207,113,8,1)" />
                </linearGradient>
            </svg>
            <RestartAltIcon sx={{ fill: "url(#linearColors)" }} />
        </>
    )

    function init() {
        let filters = {}
        attributes.forEach(att => {
            filters[att.name] = null;
        })
        return {...filters}
    }

    const [attributesFilter, setAttributesFilter] = useState(init());
    const [nameSearch, setNameSearch] = useState("");
    useEffect(() => {
        function filterByAttribute() {
            const nameSearchIsEmpty = nameSearch === null || nameSearch === undefined || nameSearch === ""
            const predicat = Object.entries(attributesFilter).every(([key, value]) => value === null) && nameSearchIsEmpty
            if (predicat) {
                setItems(defaultItems)
            } else {
                let res = defaultItems.filter(defaultItem => {
                    const predicates = Object.entries(attributesFilter).filter(([key, value]) => value !== null).every(([key, value]) => {
                        if (value !== null) {
                            return defaultItem.attributes.find(att => att.trait_type === key)?.value === value.id
                        } else {
                            return false;
                        }
                    })
                    if (!nameSearchIsEmpty) {
                        return predicates && defaultItem.name.toUpperCase().includes(nameSearch.toUpperCase())
                    } else {
                        return predicates
                    }
                })
                setItems(res);
            }
        }

        filterByAttribute()
    }, [attributesFilter, nameSearch]);


    function filterByName(text) {
        setNameSearch(text)
    }

    function onInputChangeAttributes(trait_type, value) {
    }


    function onChangeAttributes(name, value) {
        setAttributesFilter(old => ({
            ...old, [name]: value
        }))
    }

    function handleReset() {
        setNameSearch("");
        setAttributesFilter({});
    }

    return <div className={classes.SearchFields}>
        <div>
            <h2 className="result-911">RESULTS: {items.length}</h2>
        </div>
        <Button className={`${classes.Button} gradient-text`} variant="outlined" startIcon={<GradientRestartAltIcon/>}
                onClick={handleReset}>
            RESET ATTRIBUTES
        </Button>
        {/*Name*/}
        <h2 className={`${classes.FiltersTitle} gradient-text`}>By Name</h2>
        <AutocompleteCustom
            id="name-filter"
            defaultItems={defaultItems}
            setItems={setItems}
            options={items.map(item => item.name)}
            label="Name"
            variant="outlined"
            handleOnChange={filterByName}
            handleOnInputChange={filterByName}
        />
        <h2 className={`${classes.FiltersTitle} gradient-text`}>Filters by attributes</h2>
        <div className={classes.AttributesFilter}>
            {
                attributes?.map((attribute, index) => {
                    return (
                        <AutocompleteCustom
                            id={`${attribute.name}-filter`}
                            key={index}
                            variant="standard"
                            defaultItems={defaultItems}
                            setItems={setItems}
                            value={attributesFilter[attribute.name]}
                            options={attribute.values.map(attributeValue => {
                                return {
                                    label: `${attributeValue.name} (${attributeValue.number})`,
                                    id: attributeValue.name
                                }
                            })}
                            getOptionLabel={(option) => option.label}
                            label={attribute.name}
                            handleOnChange={(test) => onChangeAttributes(attribute.name, test)}
                            handleOnInputChange={(test) => onInputChangeAttributes(attribute.name, test)}
                            isOptionEqualToValue={(option, value) => option.id === value}

                        />
                    )
                })
            }
        </div>

    </div>
}

export default SearchFilters;
