import ThugzCard from "./ThugzCard";
import {makeStyles} from "@mui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
    ThugzList: {
        display: 'flex',
        flexWrap: 'wrap',

    },
    NoResult: {
        textAlign: 'center',
    }
});


function ThugzList({items}) {
    const MAX_ITEM = 20
    const classes = useStyles();
    const [nftItems, setNftItems] = useState(items.slice(1, MAX_ITEM));
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        if (items.length > MAX_ITEM) {
            setNftItems(items.slice(1, MAX_ITEM))
        } else {
            if(items.length === 0){
                setHasMore(false);
            }
            setNftItems(items);
        }
    }, [items]);


    function fetchMoreData() {
        if (nftItems.length >= items.length) {
            setHasMore(false);
            return;
        }
        setNftItems(oldItems => ([...oldItems, ...items.slice(oldItems.length, oldItems.length + MAX_ITEM)]))
    }


    return <div className={classes.ThugzList}>
        <InfiniteScroll
            dataLength={nftItems.length}
            next={fetchMoreData}
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                gap: '16px',
            }}
            hasMore={hasMore}
            pullDownToRefreshThreshold={75}
            loader={<CircularProgress color="secondary"/>
            }>
            {
                nftItems?.map((item, index) =>
                    <ThugzCard key={index} item={item}/>
                )
            }
            {
                !nftItems.length && <Typography
                    className={classes.NoResult}
                    color="white"
                >
                    No Thugz found
                </Typography>
            }
        </InfiniteScroll>
    </div>
}


export default ThugzList;
