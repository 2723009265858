import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActionArea} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import {LazyLoadImage} from 'react-lazy-load-image-component';

const useStyles = makeStyles({
    ThugzCard: {
        border: 'solid #fad965',
        borderImage: 'linear-gradient(#ffb750,#f7881b) 0.5',
        maxWidth: 345,
        borderRadius: '3px'
    },
    LazyImg: {
        width: '345px',
        height: '345px',
    },
    Rank: {
        color: 'white',
        fontFamily: 'Din pro Condensed Bold Italic !important'
    },
    Name: {
        color: 'white',
        fontFamily: 'Din Pro Condensed Italic !important'

    },
    Score: {
        color: 'white',
        fontFamily: 'Din Pro Condensed Italic !important'

    }
});

function ThugzCard({item}) {
    const classes = useStyles();
    let navigate = useNavigate();

    function handleClick() {
        navigate(
            `/thugz/:${item.name}`, {
                state: {item}
            }
        )
    }

    return <Card className={classes.ThugzCard} onClick={handleClick}>
        <CardActionArea>
            <LazyLoadImage className={classes.LazyImg}
                           src={item.image}
                           alt={item.name}
                           height="345px"
                           width="345px"
            />
            <CardContent sx={{backgroundColor: '#262626',}}>
                <Typography className={classes.Rank} gutterBottom>
                    RANK : #{item.rank}
                </Typography>
                <Typography className={classes.Name} gutterBottom>
                    Name : {item.name}
                </Typography>
                <Typography className={classes.Score} variant="body2">
                    Score : {item.score?.toFixed(2)}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
}

export default ThugzCard;
