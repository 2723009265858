import {Autocomplete} from "@mui/material";
import * as PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

function AutocompleteCustom(props) {
    return <Autocomplete
        clearOnBlur={false}
        fullWidth
        disablePortal
        id={props.id}
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        isOptionEqualToValue={props?.isOptionEqualToValue}
        sx={{
            // margin: '1rem',
            input: {
                color: 'white'
            },

            '& .MuiAutocomplete-paper': {
                // backgroundColor: '#92106e',
                background: 'linear-gradient(#ffb750,#f7881b)'
            },

            '& .MuiInput-underline:after': {
                // borderBottomColor: '#92106e',
                borderImage: 'linear-gradient(#ffb750,#f7881b) 0.5',

            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderImage: 'linear-gradient(#ffb750,#f7881b) 0.5',
                },
                '&:hover fieldset': {
                    borderImage: 'linear-gradient(#ffb750,#f7881b) 0.5',
                },
                '&.Mui-focused fieldset': {
                    borderImage: 'linear-gradient(#ffb750,#f7881b) 0.5',
                },
            },
        }}
        value={props.value}
        onChange={(event, newValue) => {
            props.handleOnChange(newValue)
        }}
        onInputChange={(event, newInputValue) => {
            props.handleOnInputChange(newInputValue)
        }}
        renderInput={(params) => <TextField
            {...params}
            variant={props.variant}
            label={props.label}
            color="secondary"
            InputLabelProps={{
                style: {color: 'white'},
            }}
        />
        }
    />;
}

AutocompleteCustom.propTypes = {
    options: PropTypes.any,
    renderInput: PropTypes.func,
    handleOnChange: PropTypes.func,
    handleOnInputChange: PropTypes.func,
    getOptionLabel: PropTypes.func,
    value: PropTypes.func,
    variant: PropTypes.string
};
export default AutocompleteCustom;
